<template>
  <SweetModal
    ref="modal"
    overlay-theme="dark"
    width="80%"
    hide-close-button
    blocking
  >
    <template #title>
      <strong class="text-muted"
        >Este modelo já consta como Ativo para esse tipo de notificação, deseja
        alterar?</strong
      >
    </template>

    <div v-if="currentModel && newModel">
      <b-row>
        <b-col class="d-flex flex-column align-items-center">
          <b-card
            border-variant="info"
            header="Modelo Atual"
            align="center"
            class="w-100"
          >
            <iframe
              id="frame"
              :srcdoc="currentModel.html"
              frameborder="0"
              style="
                width: 100%;
                height: 70vh;
                overflow-y: scroll;
                border: 1px solid lightgray;
              "
            ></iframe>
          </b-card>
        </b-col>
        <b-col class="d-flex flex-column align-items-center">
          <b-card
            border-variant="info"
            header="Deseja alterar por este?"
            align="center"
            class="w-100"
          >
            <iframe
              id="frame"
              :srcdoc="newModel.html"
              frameborder="0"
              style="
                width: 100%;
                height: 70vh;
                overflow-y: scroll;
                border: 1px solid lightgray;
              "
            ></iframe>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <template #button>
      <b-button class="mr-2" @click="submit" variant="success"
        >Alterar</b-button
      >
      <b-button @click="cancel" variant="gray">Cancelar</b-button>
    </template>
  </SweetModal>
</template>

<script>
export default {
  props: ["currentModel", "newModel"],
  methods: {
    open() {
      console.log(this.$refs);
      this.$refs.modal.open();
    },
    submit() {
      this.$emit("change_active_model");
      this.$refs.modal.close();
    },
    cancel() {
      this.$emit("cancel");
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
#frame {
  // -ms-zoom: 0.8;
  // -moz-transform: scale(0.8);
  // -moz-transform-origin: 0 0;
  // -o-transform: scale(0.8);
  // -o-transform-origin: 0 0;
  // -webkit-transform: scale(0.8);
  // -webkit-transform-origin: 0 0;
}
</style>